import React, {Fragment} from 'react'
import {Box, Typography, Button, Stack, Container} from '@mui/material'
import darkTheme from '../../src/theme';
import SectionTwoImage from '../../public/images/home/SectionTwo.png'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useRouter} from 'next/router'

function sectionTwo() {

    const Router = useRouter();

    const gotoLink = () => {
        Router.push('/link');
    }

    const SectionTwoContainer = {
        p:3,
        mt:20,
        mb:10,        
        borderRadius:6,
        [darkTheme.breakpoints.down("md")]: {
            mt:10, 
            mb:10,
        },
        '& h2':{
            fontSize: 60,
            textAlign:'center',
            fontWeight:700,
            color: darkTheme.palette.common.white,
            textTransform:'capitalize',
            mb:2,
            '& span':{
                color: darkTheme.palette.primary.main
            },
            [darkTheme.breakpoints.down("md")]: {
                fontSize: 25,
                fontWeight:500
            },
        },
        '& h4':{
            fontSize: 20,
            fontWeight:500,
            textAlign:'center',
            color: '#AEAEAE',
            zIndex: 1,
            [darkTheme.breakpoints.down("md")]: {
                fontSize:12
            },
        }
    }
    const btnContainer = {
        display:'inline-block',
        maxWidth:'240px',
        margin:'0 auto',
        mt: 2,
        mb:-6,
        zIndex: 1,
        [darkTheme.breakpoints.down("md")]: {
            mt: 4,
            mb: 3
        },
        '& .themeBtn':{
            px:5, 
            py:1.5,
            [darkTheme.breakpoints.down("md")]: {
                px:3, 
                py:1,
                fontSize:12
            },
        }
    }

    const imgContainer = {
        margin:`0 auto`,
        mt:3, 
        mb:-12,
        zIndex: 0,
        [darkTheme.breakpoints.down("md")]: {
            mb: -8,
            transform:`scale(1.35)`
        },
    }
  return (
    <Fragment>
        <Box sx={SectionTwoContainer}>
            <Container>
                <Typography component="div" sx={{display:'flex', flexDirection:'row', justifyContent:'center',width:'100%'}}>
                    <Stack display="flex" justifyContent="center" sx={{width:'100%'}}>
                        <Typography variant="h2">Transform your <span>social media followers</span> into loyal customers.</Typography>
                        <Typography variant="h4">Easily streamline your social media presence with a single, automated link that simplifies reservations, ticketing, and deal booking process for all of your followers.</Typography>
                        <Typography component="div" sx={btnContainer}>
                            <Button onClick={gotoLink} type="button" className='themeBtn' sx={{px:5, py:1.5}}>
                                Visit Clubr Link&nbsp;<ArrowForwardIcon sx={{fontSize:16}} />
                            </Button>
                        </Typography>
                        <Typography component="div" sx={imgContainer}>
                            <Image src={SectionTwoImage} alt="section two" />
                        </Typography>
                    </Stack>
                </Typography>
            </Container>
        </Box>
    </Fragment>
  )
}

export default sectionTwo