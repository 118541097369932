import React, {Fragment} from 'react'
import {Box, Typography, Button, Stack, Container} from '@mui/material'
import darkTheme from '../../src/theme';
import SectionFiveImage from '../../public/images/home/SectionFive.png'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function sectionFive() {

    const SectionFiveContainer = {
        background:'#202020',
        p:3,
        mt:12,
        mb:15,
        borderRadius:6,
        [darkTheme.breakpoints.down("md")]: {
            mb:15
        },
        '& h2':{
            fontSize: 60,
            textAlign:'center',
            fontWeight:700,
            color: darkTheme.palette.common.white,
            textTransform:'capitalize',
            mb:4,
            '& span':{
                color: darkTheme.palette.primary.main
            },
            [darkTheme.breakpoints.down("md")]: {
                fontSize: 25,
                fontWeight:500
            },
        },
        '& h4':{
            fontSize: 20,
            fontWeight:500,
            textAlign:'center',
            color: '#AEAEAE',
            mb:8,
            [darkTheme.breakpoints.down("md")]: {
                fontSize:12
            },
        }
    }
    const btnContainer = {
        display:'inline-block',
        maxWidth:'200px',
        margin:'0 auto',
        my: 4,
        [darkTheme.breakpoints.down("md")]: {
            my: 2,
        },
        '& .themeBtn':{
            px:5, 
            py:1.5,
            [darkTheme.breakpoints.down("md")]: {
                px:3, 
                py:1,
                fontSize:12
            },
        }
    }

    const imgContainer = {
        margin:`0 auto`,
        mt: 3, 
        mb:-12,
        [darkTheme.breakpoints.down("md")]: {
            mt: 4,
            mb: -8,
            transform:`scale(1.35)`
        },
    }
  return (
    <Fragment>
        <Box sx={SectionFiveContainer}>
            <Container>
                <Typography component="div" sx={{display:'flex', flexDirection:'row', justifyContent:'center',}}>
                    <Stack display="flex" justifyContent="center" sx={{width:{md:'80%', sm:'100%'}}}>
                        <Typography variant="h2">Automate your Existing <span>Communication Channels</span> </Typography>
                        <Typography variant="h4">ClubrBot is an advanced Generative AI assistant that enhances customer engagement and satisfaction while seamlessly handling customer queries, simplifying table reservations, and furnishing valuable insights about your establishment.</Typography>
                        {/* <Typography component="div" sx={btnContainer}>
                            <Button type="button" className='themeBtn'>
                                Know More&nbsp;<ArrowForwardIcon sx={{fontSize:16}} />
                            </Button>
                        </Typography> */}
                        <Typography component="div" sx={imgContainer}>
                            <Image src={SectionFiveImage} alt="section five" />
                        </Typography>
                    </Stack>
                </Typography>
            </Container>
        </Box>
    </Fragment>
  )
}

export default sectionFive