import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "next/link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import Image from "next/image";
import darkTheme from "../../src/theme";
import OnTicketIcon from "../../public/images/icons/OnTicketIcon.png";
import FeaturedIcon from "../../public/images/icons/FeaturedIcon2.svg";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationIcon from "../../public/images/icons/Vector.png";
import { isMobile } from "react-device-detect";

const eventCard = {
  background: darkTheme.palette.background.secondary,
  padding: darkTheme.spacing(1),
  background: "none",
  // textTransform: 'capitalize',
  transition: "transform 0.35s ease-in-out !important",
  boxShadow: "none !important",
  transition: "0.5s !important",
  borderRadius: darkTheme.spacing(1),
  marginBottom: darkTheme.spacing(3),
  cursor:'pointer', 
  "& h4": {
    fontSize: `20px !important`,
    fontWeight: 400,
    textTransform: "capitalize !important",
    color: `${darkTheme.palette.primary.title} !important`,
    lineHeight: "30px",
  },
  "& h5": {
    fontSize: 14,
    fontWeight: 400,
    textTransform: "capitalize !important",
    color: darkTheme.palette.primary.dates,
  },
  "& h6": {
    fontSize: 14,
    color: darkTheme.palette.primary.location,
    fontWeight: 400,
    marginTop: darkTheme.spacing(0.75),
    marginBottom: darkTheme.spacing(0.75),
    textTransform: "capitalize !important",
  },
  "&:hover": {
    padding: darkTheme.spacing(1),
    transform: "scale(1.085)",
    zIndex: 1,
    boxShadow: darkTheme.shadows[8],
    transition: "0.5s !important",
  },
  [darkTheme.breakpoints.down("sm")]: {
    marginBottom: darkTheme.spacing(2),
    "& h4": {
      fontSize: `18px !important`,
      marginTop: darkTheme.spacing(1),
      marginBottom: darkTheme.spacing(0),
      textTransform: "capitalize !important",
    },
    "& h5": {
      fontSize: `16px !important`,
      marginTop: `0px !important`,
      marginBottom: darkTheme.spacing(0),
    },
    "& h6": {
      fontSize: `14px !important`,
      marginTop: `0px !important`,
      marginBottom: darkTheme.spacing(1.5),
    },
    "&:hover": {
      transform: "scale(1)",
      boxShadow: darkTheme.shadows[0],
      transition: "0.5s !important",
    },
  },
};

const Eventcard = (props) => {
  const [data, setData] = useState(props.data);
  const [tickets, setTickets] = useState([]);
  const [startingPrice, setStartingPrice] = useState(0);
  const [ticketStatus, setTicketStatus] = useState(0);
  const [reserveStatus, setReserveStatus] = useState(0);
  useEffect(() => {
    const logic = async () => {
      let res2 = await axios.get(
        `/api/events/tickets?slug=${props.data.slug}&id=${props.data.id}`,
        {
          headers: {
            "x-auth-token": process.env.JWT_TOKEN,
          },
        }
      );

      setTickets(res2.data ? res2.data.result : []);
      setStartingPrice(tickets.length > 0 ? tickets[0].price : 0);
      let reserveStatus = tickets.some(
        (ticket) => ticket.price > 0 && ticket.status == 1
      );
      setReserveStatus(reserveStatus);
      let ticketStatus = tickets.some((ticket) => ticket.status == 1);
      setTicketStatus(ticketStatus);
    };

    logic();

    // }, [tickets]);
  }, []);

  const EVENT_TITLE_MAX_LENGTH = 35;
  const EVENT_VENUE_MAX_LENGTH = 25;

  return (
    <Typography component="div">
      {props.data ? (
        <Link href={"/event/" + props.data.slug + "/" + props.data.id}>
         
            <Card sx={eventCard}>
              {/* {!isMobile ? ( */}
                <div >
                  <CardMedia
                    component="img"
                    image={
                      props.data.img_path != "null"
                        ? props.data.img_path
                        : 'https://clubrstorage.blob.core.windows.net/clubr-img/default.jpg'
                    }
                    height={250}
                    width={`100%`}
                    alt={props.data.title}
                    sx={{ borderRadius: darkTheme.spacing(1) }}
                  />
                  {/* <Image
                    src={
                      props.data.img_path != "null"
                        ? props.data.img_path
                        : 'https://clubrstorage.blob.core.windows.net/clubr-img/default.jpg'
                    }
                    height={250}
                    width={250}
                    alt={props.data.title}
                    objectFit="cover"
                    style={{
                      width: "100%",
                      borderRadius: darkTheme.spacing(1),
                    }}
                  /> */}
                  {data.featured ? (
                    <Typography
                      component="span"
                      sx={{
                        position: "absolute",
                        left: 10,
                        top: 10,
                        color: darkTheme.palette.common.black,
                        fontWeight: 700,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Image
                        src={FeaturedIcon}
                        height={24}
                        width={24}
                        alt="..."
                      />
                    </Typography>
                  ) : (
                    ""
                  )}

                  {ticketStatus ? (
                    <Button
                      variant="contained"
                      className="priceBtn"
                      sx={{
                        minWidth: 90,
                        padding: "5px 10px",
                        position: "absolute",
                        right: 10,
                        top: 205,
                        color: darkTheme.palette.common.black,
                        fontWeight: 700,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Image
                        src={OnTicketIcon}
                        height={15}
                        width={20}
                        alt="..."
                        style={{ paddingRight: "5px" }}
                      />{" "}
                      {reserveStatus && startingPrice > 0 ? (
                        <>
                          <CurrencyRupeeIcon sx={{ mr: 0.2, fontSize: 14 }} />
                          {startingPrice}
                        </>
                      ) : (
                        " RSVP"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                  <CardContent
                    sx={{
                      p: 0,
                      pt: {
                        md: 2,
                        sm: 0,
                        xs: 0,
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ textTransform: "capitalize !important" }}
                    >
                      {(props.data.title.length >= EVENT_TITLE_MAX_LENGTH
                        ? props.data.title
                            .toLowerCase()
                            .substring(0, EVENT_TITLE_MAX_LENGTH) + "..."
                        : props.data.title.toLowerCase()
                      ).toLowerCase()}
                    </Typography>
                    {props.data.clubName && (
                      <Typography variant="h6">
                        <Image
                          src={LocationIcon}
                          alt=""
                          width={12}
                          height={16}
                          layout="fixed"
                        />
                        &nbsp;
                        {(props.data.clubName.length >= EVENT_VENUE_MAX_LENGTH
                          ? props.data.clubName.substring(
                              0,
                              EVENT_VENUE_MAX_LENGTH
                            ) + "..."
                          : props.data.clubName
                        ).toLowerCase()}
                      </Typography>
                    )}

                    <Stack
                      direction={{ sm: "column", md: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      justifyContent="space-between"
                      alignItems="flex-end"
                      sx={{
                        alignItems: {
                          md: "flex-end",
                          sm: "flex-start",
                        },
                      }}
                    >
                      {props.data.from_date != "0000-00-00" ? (
                        <Typography variant="h5">
                          {moment(props.data.from_date).format("ddd DD, MMM")} -{" "}
                          {moment(props.data.to_date).format("ddd DD, MMM")}
                        </Typography>
                      ) : (
                        <Typography variant="h5">
                          {moment(props.data.only_today).format("ddd DD, MMM")}
                        </Typography>
                      )}
                    </Stack>
                  </CardContent>
                </div>
              
              {/* )} */}
            </Card>
         
        </Link>
      ) : (
        <Stack spacing={2}>
          <Skeleton variant="rectangular" height={240} />
          <Skeleton variant="h1" />
          <Skeleton variant="text" />
        </Stack>
      )}
    </Typography>
  );
};

export default Eventcard;
