import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Link from 'next/link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment'
import Skeleton from '@mui/material/Skeleton';
import DateIcon from '../../public/images/icons/Date.svg'
import EyeIcon from '../../public/images/icons/Eye.svg'
import Image from 'next/image'
import parse from "html-react-parser";
import darkTheme from '../../src/theme';

const blogCardContainer = {
    textTransform: 'none !important',
    boxShadow: darkTheme.shadows[0],
    borderRadius: `0 !important`,
    paddingBottom: darkTheme.spacing(5),
    transition:'0.3s',    
    '&:hover':{
        '& img':{
            borderRadius: 3,     
            transform:`scale(1.05)`,
            overflow:'hidden'
        } 
    },
    '& h3': {
        fontSize: 20,
        color: darkTheme.palette.common.white,
        fontWeight: 700,
        marginBottom: darkTheme.spacing(2),
        textTransform: 'capitalize !important',
        mb:0,
    },
    '& h5': {
        fontSize: 14,
        color: darkTheme.palette.grey[300],
        fontWeight: 500
    },
    '& h6': {
        fontSize: 14,
        color: darkTheme.palette.grey[400],
        fontWeight: 500
    },
};

const cardMedia = {
    zIndex: 1,
    height: "inherit",
    borderRadius: 3,     
    transition:'0.3s',
    '&:hover':{
        borderRadius: 3,     
        transform:`scale(1.05)`,
    }   
};

const BlogCard = (props) => {    
    const [data, setData] = useState(props.data);            
    const BLOG_TITLE_MAX_LENGTH = 34  
    const BLOG_TITLE_MAX_LENGTH_MIN = 20  
    const BLOG_DESC_MAX_LENGTH = 100          
    
    return (
    <div>
        {data ? (
            <Card sx={blogCardContainer}> 
                <Link href={'mag/' + data.slug } passHref>
                <a href={'mag/' + data.slug }>        
                    <CardContent sx={{px:0}}>
                        <Typography variant="h3" className='desktopMode'>
                            {(data.title.length >= BLOG_TITLE_MAX_LENGTH ? data.title.substring(0,BLOG_TITLE_MAX_LENGTH)+'...' : data.title).toLowerCase() }
                        </Typography>
                        <Typography variant="h3" className='mobileMode'>
                            {(data.title.length >= BLOG_TITLE_MAX_LENGTH_MIN ? data.title.substring(0,BLOG_TITLE_MAX_LENGTH_MIN)+'...' : data.title).toLowerCase() }
                        </Typography> 
                        <Stack sx={{flexDirection:'row', justifyContent:'space-between'}}>                                                                                      
                            <Typography variant="h5">
                                <Image src={DateIcon} alt="" width={14} height={14} layout="fixed" />&nbsp;&nbsp;{moment(data.created_date).format('ddd, DD MMM YYYY')}
                            </Typography>                                
                            <Typography variant="h5">
                                <Image src={EyeIcon} alt="" width={14} height={14} layout="fixed" />&nbsp;&nbsp;{data.total_views} Views
                            </Typography>                                                                                
                        </Stack>        
                    </CardContent>  
                    <CardMedia
                        component="img"
                        sx={cardMedia}
                        image={data.img_path ? data.img_path : 'https://clubrstorage.blob.core.windows.net/clubr-img/default.jpg'}
                        title={data.title}
                    /> 
                    <Typography variant="h6" sx={{mt:4}}>
                    {parse(data.description.length >= BLOG_DESC_MAX_LENGTH ? (data.description).substring(0,BLOG_DESC_MAX_LENGTH)+'...' : data.description)}
                    </Typography> 
                    </a>
                </Link>
            </Card>            
        ) : (
            <Stack spacing={2}>
                <Skeleton variant="rectangular"  height={240} />
                <Skeleton variant="h1" />                    
                <Skeleton variant="text"/>                                        
            </Stack>
        )}
    </div>
    );
}

export default BlogCard;

