import React, { useState, useEffect, Fragment} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import EventCard from "../components/cards/EventCard";
import { nanoid } from "nanoid";
import Link from "next/link";
import { parseCookies } from "nookies";
import darkTheme from '../src/theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const eventsContainer = {
  marginTop: darkTheme.spacing(5),
  marginBottom: darkTheme.spacing(5),
  '& h3': {
    fontSize: 30,
    fontWeight: 500,
    color: darkTheme.palette.primary.title,    
    marginBottom: darkTheme.spacing(2),
    [darkTheme.breakpoints.down("md")]: {
      marginBottom: darkTheme.spacing(2),
      fontSize: 30,
      fontWeight: 500,
    },
    [darkTheme.breakpoints.down("sm")]: {
      marginBottom: darkTheme.spacing(2),
      fontSize: 20,
      fontWeight: 500,
    },
  },
};

const viewAllLink = {
  display: 'flex',
  alignItems: 'center',  
  color: darkTheme.palette.grey[200],
  marginBottom: darkTheme.spacing(2),
  '& :hover': {
    color: darkTheme.palette.common.white,
  }
};
  
function HomeEvents(props) {  
  const [data, setData] = useState(props.events);
  const [loading, setLoading] = useState(props.events ? false : true);
  const [cityName, setCityName] = useState("");
  let skeletonCards = Array(8).fill(0);
  const cookieCity = parseCookies();
  useEffect(() => {
    const city = cookieCity.city ? cookieCity.city : "";
    if (city) {
      setCityName(city);
    } else {
      setCityName("");
    }
  }, [props]);

  return (
    <>
      {loading ? (
        <Typography component="div" sx={eventsContainer}>          
          <Typography variant="h3">Upcoming Gigs</Typography>
          <Grid container spacing={3}>
            {skeletonCards.map((index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={nanoid(30)}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={300}
                  sx={{ borderRadius: darkTheme.spacing(1) }}
                  key={"sk" + index}
                />
                <Stack sx={{ mt: 3 }}>
                  <Skeleton
                    animation="wave"
                    variant="h3"
                    sx={{ color: "grey.900" }}
                  />
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Skeleton
                    animation="wave"
                    width="40%"
                    variant="body1"
                    sx={{ color: "grey.900" }}
                  />
                  <Skeleton
                    animation="wave"
                    width="40%"
                    variant="body1"
                    sx={{ color: "grey.900" }}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>          
        </Typography>
      ) : (
        <Typography component="div" sx={eventsContainer}>
          <Typography component="div">        
          {data ? (
                <>   
                  <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="bottom">      
                    <Typography variant="h3">Featured Events</Typography>
                    <Typography component="h6" alignSelf="center" display="flex">
                      <Link href={cityName == '' ? '/all-events' : `/${cityName}/all-events`} sx={{display:'flex', alignSelf:'center'}} passHref>
                        <Typography component="a" sx={viewAllLink}>
                          <span className="rippleBtn" style={{marginLeft:'7px'}}><span><ArrowForwardIcon sx={{fontSize:'14px', color: darkTheme.palette.primary.arrowColor, zIndex:3}}/></span></span></Typography>
                      </Link>
                    </Typography>
                  </Stack>
              <Swiper
                  modules={[Navigation, Pagination, Autoplay, FreeMode]}
                  spaceBetween={15}
                  slidesPerView={1.25}                  
                  freeMode={true}
                  navigation
                  effect={'fade'}
                  autoHeight={true}
                  autoplay={false}
                  loop={false}  
                  breakpoints={{
                      1023: {
                          width: 1023,
                          slidesPerView: 3.5,
                      },
                      990: {
                          width: 990,
                          slidesPerView: 3,
                      },
                      768: {
                          width: 768,
                          slidesPerView: 2,
                      },
                      425: {
                          width: 425,
                          slidesPerView: 1.5,
                      },
                  }}                
                >                  
                {data.map((event) => (
                  <Grid container key={nanoid(30)}>
                    <Grid item sm={12} xs={12} md={6}>                  
                      <SwiperSlide>
                        <EventCard data={event} />
                      </SwiperSlide>    
                    </Grid>
                  </Grid>              
                ))}                
              </Swiper>
            </>          
          ):('')}
          </Typography>  
        </Typography>
      )}
    </> 
  );
}
export default HomeEvents;
