import React, {Fragment} from 'react'
import {Box, Typography, Button, Stack, Container} from '@mui/material'
import darkTheme from '../../src/theme';
import SectionFourImage from '../../public/images/home/SectionFour.gif'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ScrollAnimation from 'react-animate-on-scroll';



function sectionFour() {

    const SectionFourContainer = {
        p:3,
        mt:10,
        mb:10,
        borderRadius:6,
        [darkTheme.breakpoints.down("md")]: {
            mt:10, 
            mb:10
        },
        '& h2':{
            fontSize: 60,
            textAlign:'center',
            fontWeight:700,
            color: darkTheme.palette.common.white,
            textTransform:'capitalize',
            mb:2,
            '& span':{
                color: darkTheme.palette.primary.main
            },
            [darkTheme.breakpoints.down("md")]: {
                fontSize: 25,
                fontWeight:500
            },
        },
        '& h4':{
            fontSize: 20,
            fontWeight:500,
            textAlign:'center',
            color: '#AEAEAE',
            [darkTheme.breakpoints.down("md")]: {
                fontSize:12
            },
        }
    }
    const btnContainer = {
        display:'inline-block',
        maxWidth:'200px',
        margin:'0 auto',
        mt: 4,
        mb: -4,
        [darkTheme.breakpoints.down("md")]: {
            my: 2,
        },
        '& .themeBtn':{
            px:5, 
            py:1.5,
            [darkTheme.breakpoints.down("md")]: {
                px:3, 
                py:1,
                fontSize:12
            },
        }
    }

    const imgContainer = {
        mt:0, 
        mb:-12,
        [darkTheme.breakpoints.down("md")]: {
            mt:3,
            mb: -4,
            transform:`scale(1.35)`
        },
    }
    
  return (
    <Fragment>
        <Box sx={SectionFourContainer}>
            <Container>
                <Typography component="div" sx={{display:'flex', flexDirection:'row', justifyContent:'center',width:'100%'}}>
                    <Stack display="flex" justifyContent="center" sx={{width:{md:'80%', sm:'100%'}}}>
                        <Typography variant="h2"><span>Data & Analytics</span> at your fingertips:</Typography> 
                        <Typography variant="h4">Clubr offers a comprehensive range of essential data metrics and real-time analytics that provide valuable insights into events. Whether it's check-in statistics, ticket sales, payment modes, or more, Clubr delivers crucial information that can help venues and promoters make informed decisions about their events.</Typography>
                        {/* <Typography component="div" sx={btnContainer}>
                            <Button type="button" className='themeBtn' sx={{px:5, py:1.5}}>
                                Know More&nbsp;<ArrowForwardIcon sx={{fontSize:16}} />
                            </Button>
                        </Typography> */}
                        <Typography component="div" sx={imgContainer}>
                            <Image src={SectionFourImage} alt="section two" />
                        </Typography>
                    </Stack>
                </Typography>
            </Container>
        </Box>
    </Fragment>
  )
}

export default sectionFour