import { React, useState, useEffect, Fragment } from "react";
import { Typography, Container, Grid } from "@mui/material";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "@mui/material/Skeleton";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import HomeBlogCard from "./cards/HomeBlogCard";
import { parseCookies } from "nookies";
import { nanoid } from 'nanoid'
import darkTheme from '../src/theme';
import axios from 'axios'

const blogsContainer = {
    width:'100%',
    display:'block',
    marginTop: darkTheme.spacing(20),
    marginBottom: darkTheme.spacing(5),
    '& h2':{
      fontSize: 40,
      textAlign:'center',
      fontWeight:700,
      color: darkTheme.palette.common.white,
      textTransform:'capitalize',
      mb:3,
      '& span':{
          color: darkTheme.palette.primary.main
      },
      [darkTheme.breakpoints.down("md")]: {
          fontSize: 25,
          fontWeight:500
      },
  },
  '& h5': {
    my: 3,
    fontSize: 15,
    fontWeight: 500,
    color: `#AEAEAE`,  
  }
};
        

function HomeClubs() {    
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  const fetchData = async () => { 
    await axios
    .get(`/api/blogs/list?page=paths`, {
      headers: {
        "x-auth-token": process.env.JWT_TOKEN,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        setData(response.data.result ? response.data.result : [])
        setLoading(false)
      }
    })
    .catch(err => {
      console.log(err);
    }) 
  };

  useEffect(() => {
    fetchData();
  }, []);

  let skeletonCards = Array(2).fill(0);

  return (
    <Typography component="div" sx={{width:'100%'}}>
      <>
        {loading ? (
          <Typography component="div" sx={blogsContainer}>
              <Typography variant="h2">Latest on <span>Clubr Mag</span></Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {skeletonCards.map((index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}                  
                    key={nanoid(30)}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={300}
                      sx={{ borderRadius: darkTheme.spacing(1), color: "grey.900" }}
                      key={"sk" + index}
                    />
                  </Grid>
                ))}
              </Grid>         
          </Typography>
        ) : (
          <Fragment>  
              {data && (
                  <Typography component="div" sx={blogsContainer}>
                      <Typography variant="h2">Latest on <span>Clubr Mag</span></Typography>
                      <Grid container spacing={4} sx={{ mt: 2, pl:4 }}>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, FreeMode]}
                            spaceBetween={25}
                            slidesPerView={1.35}                  
                            freeMode={true}
                            navigation
                            effect={'fade'}
                            autoHeight={true}                                    
                            autoplay={false}
                            loop={false}  
                            breakpoints={{
                                1023: {
                                    width: 1023,
                                    slidesPerView: 2.25,
                                },
                                990: {
                                    width: 990,
                                    slidesPerView: 2.25,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                425: {
                                    width: 425,
                                    slidesPerView: 1.5,
                                },
                            }}      
                        >
                            {data.map((blog) => (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={nanoid(30)}
                            >
                                <SwiperSlide key={blog.id}>
                                    <HomeBlogCard data={blog} />
                                </SwiperSlide>
                            </Grid>
                            ))}
                        </Swiper>
                      </Grid>
                  </Typography>            
              )}
          </Fragment>
        )}
      </>
    </Typography>
  );
}

export default HomeClubs;
