import React, { Fragment } from "react";
import { Typography, Stack } from '@mui/material';
import darkTheme  from '../../src/theme';

const bannerContainer = {
    marginTop: darkTheme.spacing(2),
    marginBottom: darkTheme.spacing(3),
    '& .myVideoContent':{
        backgroundImage:`url('../../images/overlays/05.png')`
    },
    [darkTheme.breakpoints.down("md")]: {
        marginTop: darkTheme.spacing(6),
    },
    '& h1':{
        fontSize: 60,
        textAlign:'center',
        fontWeight:700,
        color: darkTheme.palette.common.white,
        textTransform:'capitalize',
        mb:2,
        '& span':{
            color: darkTheme.palette.primary.main,
        },
        [darkTheme.breakpoints.down("md")]: {
            fontSize: 30,
            fontWeight:700
        },
    },
    '& h5':{
        fontSize: 20,
        fontWeight:500,
        textAlign:'center',
        color: darkTheme.palette.primary.title,
        [darkTheme.breakpoints.down("md")]: {
            fontSize:12,
        },
    }
};

const BannerHome = () => {      
    return (
        <Fragment>
            <Typography component="div" className="myvideo" sx={{...bannerContainer}}>
                {/* <WistiaPlayer videoId={'aeao04hu9q'} wrapper="wistia_async_aeao04hu9q" /> */}
            
            
                <video src={'https://res.cloudinary.com/dtfofsbrk/video/upload/v1693466429/home_nc4gn7.mp4'} autoPlay loop muted playsinline="true" disablePictureInPicture="true" />
                <Typography component="div" className="myVideoContent">
                    <Stack display="flex" justifyContent="center" alignItems="center" 
                    sx={{
                        width:'70%',
                        margin:`0 auto`,
                    }}
                    >
                        <Typography variant="h1"><span>Crafting</span> an Ecosystem around <span>Live Entertainment</span></Typography>
                        <Typography variant="h5">Clubr360 is a comprehensive suite of productivity tools designed to enhance the efficiency of venues, promoters, content creators, and artists. Our platform helps them to increase their revenue and establish a sustainable brand identity.</Typography>
                    </Stack>
                </Typography>
            </Typography>
        </Fragment>   
    )
}

export default BannerHome
