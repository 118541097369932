import React, {Fragment} from 'react'
import {Box, Typography, Button, Stack, Container} from '@mui/material'
import darkTheme from '../../src/theme';
import SectionThreeImage from '../../public/images/home/SectionThree.png'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useRouter } from 'next/router';

function sectionThree() {

    const Router = useRouter();

    const gotoLink = () => {
        Router.push('https://tribe.clubr.in');
    }

    const SectionThreeContainer = {
        background:'#202020',
        p:3,
        mt:20,
        mb:10,        
        borderRadius:6,
        [darkTheme.breakpoints.down("md")]: {
            mt:20, 
            mb:10
        },
        '& h2':{
            fontSize: 60,
            textAlign:'center',
            fontWeight:700,
            color: darkTheme.palette.common.white,
            textTransform:'capitalize',
            mb:2,
            '& span':{
                color: darkTheme.palette.primary.main
            },
            [darkTheme.breakpoints.down("md")]: {
                fontSize: 25,
                fontWeight:500
            },
        },
        '& h4':{
            fontSize: 20,
            fontWeight:500,
            textAlign:'center',
            color: '#AEAEAE',
            [darkTheme.breakpoints.down("md")]: {
                fontSize:12
            },
        }
    }
    const btnContainer = {
        display:'inline-block',
        maxWidth:'240px',
        margin:'0 auto',
        my: 4,
        [darkTheme.breakpoints.down("md")]: {
            mt: 4,
            mb: 6,
        },
        '& .themeBtn':{
            px:5, 
            py:1.5,
            [darkTheme.breakpoints.down("md")]: {
                px:3, 
                py:1,
                fontSize:12
            },
        }
    }

    const imgContainer = {
        margin:`0 auto`,
        mt:3, 
        mb:-4,
        [darkTheme.breakpoints.down("md")]: {
            mb: -4,
            transform:`scale(1.35)`
        },
    }
  return (
    <Fragment>
        <Box sx={SectionThreeContainer}>
            <Container>
                <Typography component="div" sx={{display:'flex', flexDirection:'row', justifyContent:'center',}}>
                    <Stack display="flex" justifyContent="center" sx={{width:{md:'80%', sm:'100%'}}}>
                        <Typography variant="h2"><span>Influencer Affiliate platform</span> for Live Events</Typography>
                        <Typography variant="h4">Join Clubr Tribe, the ultimate community for Influencers and Nightlife Enthusiasts to earn cash and score free passes by collaborating with top Nightlife Venues and event organisers to promote their events.</Typography>
                        <Typography component="div" sx={btnContainer}>
                            <Button type="button" onClick={gotoLink} className='themeBtn' sx={{px:5, py:1.5}}>
                                Visit Clubr Tribe&nbsp;<ArrowForwardIcon sx={{fontSize:16}} />
                            </Button>
                        </Typography>
                        <Typography component="div" sx={imgContainer}>
                            <Image src={SectionThreeImage} alt="section three" />
                        </Typography>
                    </Stack>
                </Typography>
            </Container>
        </Box>
    </Fragment>
  )
}

export default sectionThree