import React, { useState, Fragment } from 'react'
import {Box, Typography, Button, Stack, Container} from '@mui/material'
import darkTheme from '../../src/theme';
import SectionOneImage from '../../public/images/home/SectionOne.gif'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useRouter } from 'next/router';

function sectionOne() {

    const Router = useRouter();

    const SectionOneContainer = {
        background:'#202020',
        p:3,
        mt:10,
        mb:10,
        borderRadius:6,
        [darkTheme.breakpoints.down("md")]: {
            mt:5, 
            mb:10
        },
        '& h2':{
            fontSize: 60,
            textAlign:'center',
            fontWeight:700,
            color: darkTheme.palette.common.white,
            textTransform:'capitalize',
            mb:2,
            '& span':{
                color: darkTheme.palette.primary.main,
            },
            [darkTheme.breakpoints.down("md")]: {
                fontSize: 25,
                fontWeight:500
            },
        },
        '& h4':{
            fontSize: 20,
            fontWeight:500,
            textAlign:'center',
            color: '#AEAEAE',
            [darkTheme.breakpoints.down("md")]: {
                fontSize:12
            },
        }
    }

    const btnContainer = {
        display:'inline-block',
        maxWidth:'240px',
        margin:'0 auto',
        mt: 4,
        mb: 0,
        [darkTheme.breakpoints.down("md")]: {
            mt: 4,
            mb: 6
        },
        '& .themeBtn':{
            px:5, 
            py:1.5,
            [darkTheme.breakpoints.down("md")]: {
                px:3, 
                py:1,
                fontSize:12
            },
        }
    }

    const imgContainer = {
        mt:3, 
        mb:-14,
        [darkTheme.breakpoints.down("md")]: {
            mb: -4,
            transform:`scale(1.35)`
        }
    }

    const gotoLink = () => {
        Router.push('/backstage');
    }
  return (
    <Fragment>
        <Box sx={SectionOneContainer}>
            <Container>
                <Typography component="div" sx={{display:'flex', flexDirection:'row', justifyContent:'center',}}>
                    <Stack display="flex" justifyContent="center" sx={{width:{md:'80%', sm:'100%'}}}>
                        <Typography variant="h2">The <span>Most Powerful Platform</span> Ever For Your Events</Typography>
                        <Typography variant="h4">Go live with an Omni channel solution Website & Mobile Apps* & offer holistic brand experience in under 30 mins.</Typography>
                        <Typography component="div" sx={btnContainer}>
                            <Button type="button" onClick={gotoLink} className='themeBtn' sx={{px:5, py:1.5}}>
                                Visit Backstage&nbsp;<ArrowForwardIcon sx={{fontSize:16}} />
                            </Button>
                        </Typography>
                            
                        <Typography component="div" sx={imgContainer}>
                            <Image src={SectionOneImage} alt="section one" />
                        </Typography>
                       
                    </Stack>
                </Typography>
            </Container>
        </Box>
    </Fragment>
  )
}

export default sectionOne