import React, { Fragment } from "react";
import HomeEventsNew from "../components/HomeEventsNew";
import HomeBlogs from "../components/HomeBlogs";
import BannerHomeNew from "../components/sliders/BannerHomeNew";
import Head from "next/head";
import axios from "axios";
import { server } from "../config";
import darkTheme from '../src/theme';
import { Typography } from "@mui/material";
import SectionOne from '../components/homeSections/sectionOne'
import SectionTwo from '../components/homeSections/sectionTwo'
import SectionThree from '../components/homeSections/sectionThree'
import SectionFour from '../components/homeSections/sectionFour'
import SectionFive from '../components/homeSections/sectionFive'

const homePageContainer = {         
  marginBottom: darkTheme.spacing(0),               
};
      

export const getStaticProps = async function () {
  try {
    let events = [];
    let eventsTotal = 0;
  
    await axios
      .get(`${server}/api/events/featured?start=0&limit=8`, {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          events = response.data.result ? response.data.result : [];
        }
      })
      .catch(err => {
        console.log(err);
      })
  
    await axios
      .get(`${server}/api/events/homeTotal`, {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          eventsTotal = response.data.result ? response.data.result : 0;
        }
      })
      .catch(err => {
        console.log(err);
      })
  
    const schema = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "founder": "Rajesh Sukamanchi",
      "contactPoint": {
        "@type": "ContactPoint",
        "email": "info@clubr.in",
        "telephone": "+917993793579",
        "hoursAvailable": {
          "@type": "OpeningHoursSpecification",
          "opens": "10:00",
          "closes": "19:00"
        }
      },
      "email": "info@clubr.in",
      "foundingLocation": {
        "@type": "Place",
        "name": "Hyderabad, Telangana"
      },
      "legalName": "Clubr Technoligies Private Limited",
      "telephone": "+917993793579",
      "location": {
        "@type": "Place",
        "name": "Clubr Technoligies Private Limited",
        "address": [
          {
            "@type": "PostalAddress",
            "name": "FIIRE, The Don Bosco College of Engineering, Fatorda, Goa 403601"
          },
          {
            "@type": "PostalAddress",
            "name": "Level 3, Aditya Enclave, Venkatagiri, Jubilee Hills, Hyderabad, Telangana 500033"
          }
        ]
      },
      "logo": "https://clubr.in/new-logo.png"
    }`;
  
    return {
      props: { events, eventsTotal, schema },
      revalidate: 1
    };
  } catch (e) {
    return {notFound: true}
  }
};

function Home(props) { 
  

  

  return (
    <Fragment>
      <Head>
        <title>Crafting An Ecosystem Around Live Entertainment</title>
        <meta name="description" content="Clubr360 is a comprehensive suite of productivity tools designed to enhance the efficiency of venues, promoters, content creators, and artists. Our platform helps them to increase their revenue and establish a sustainable brand identity."></meta>
        <meta property="og:title" content={`Crafting An Ecosystem Around Live Entertainment`}></meta>
        <meta property="og:description" content={`Clubr360 is a comprehensive suite of productivity tools designed to enhance the efficiency of venues, promoters, content creators, and artists. Our platform helps them to increase their revenue and establish a sustainable brand identity.`}></meta>
        <meta property="og:image" content={'https://clubrstorage.blob.core.windows.net/clubr-img/OGImageHome.jpg'}></meta>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Clubr" />
        <meta property="og:url" content={`https://clubr.in`} />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="@Clubr14"></meta>
        <meta name="twitter:creator" content="@Clubr14"></meta>
        <meta name="twitter:title" content={`Crafting An Ecosystem Around Live Entertainment`}></meta>
        <meta name="twitter:description" content={`Clubr360 is a comprehensive suite of productivity tools designed to enhance the efficiency of venues, promoters, content creators, and artists. Our platform helps them to increase their revenue and establish a sustainable brand identity.`}></meta>
        <meta name="twitter:image" content={'https://clubrstorage.blob.core.windows.net/clubr-img/OGImageHome.jpg'}></meta>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: props.schema }}
        />        
      </Head>
      <Typography component="div" sx={homePageContainer} className={"main_sec"}>        
        <BannerHomeNew />
        {props.events.length > 0 && 
          <HomeEventsNew events={props.events} total={props.eventsTotal} />
        }
        <SectionOne />
        <SectionTwo />
        {/* <SectionThree /> */}
        <SectionFour />
        <SectionFive />
        <HomeBlogs />
      </Typography>
    </Fragment>
  );
}
export default Home;
